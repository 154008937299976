class BuchhandlungNavigation extends HTMLElement {
  connectedCallback() {
    this.initScrollListener();
    this.getContentblocksMarginTop();
  }

  getContentblocksMarginTop() {
    const contentBlocks = document.querySelectorAll(".contentblock");
    contentBlocks.forEach(contentBlock => {
      let marginTop;
      if (contentBlock.id === "veranstaltungen") {
        marginTop = 0;
      } else {
        marginTop = contentBlock.getBoundingClientRect().top;
      }
      this.setMarginInfoToNavigationButtons(contentBlock.id, marginTop)
    });
  }

  buttonActiveOnClick() {
    this.querySelectorAll("button").forEach(button => {
      button.addEventListener("click", () => {
        button.setAttribute("active", "")
      })
    })
  }

  changeActiveStatus(scrollingPosition) {
    const scrollPosition = Math.abs(scrollingPosition);

    const buttons = this.querySelectorAll("button");

    buttons.forEach(function(button, index, array) {     
      if (index === array.length - 1) {
        if (scrollPosition >= button.getAttribute("abstand")) {
          button.setAttribute("active", "");
        } else {
          button.removeAttribute("active")
        }
      } else {
        let nextSibling = button.closest("li").nextElementSibling.querySelector("button");
        if (scrollPosition >= button.getAttribute("abstand") && scrollPosition < nextSibling.getAttribute("abstand")) {
          button.setAttribute("active", "");
        } else {
          button.removeAttribute("active")
        }
      }
    });
  }

  setMarginInfoToNavigationButtons(id, marginTop) {
    const marginRounded = window.pageYOffset + (Math.floor(marginTop));
    const button = this.querySelector(`.${id}`);
    button.setAttribute("abstand", marginRounded);
  }

  initScrollListener() {
    window.addEventListener("scroll", () => {
      this.getContentblocksMarginTop();
      this.changeActiveStatus((document.body.getBoundingClientRect()).top);
    });
  }

}
if (!customElements.get("buchhandlung-navigation")) {
  customElements.define("buchhandlung-navigation", BuchhandlungNavigation);
}
